import React from 'react'
import Video from '../components/video'

export default function IndexPage({ location }) {

	const videoSource = location.pathname.replace(/^\/?|\/?$/g, "")

	return (
		<Video source={videoSource}/>
    )
}
