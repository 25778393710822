import React from 'react'
import { Helmet } from 'react-helmet'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import * as styles from './video.module.css'

export default function Video({ source }) {

	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					siteUrl
				}
			}
		}
	`)

	function skipClicked() {
		navigate("/")
	}

	return (
		<>
			<Helmet htmlAttributes={{ class: "cinema"}}>
				<meta property="og:title" content="cubs.cloud"/>
				<meta property="og:type" content="website"/>
				<meta property="og:url" content={`${data.site.siteMetadata.siteUrl}/${source}.html`}/>
				<meta property="og:image" content={`${data.site.siteMetadata.siteUrl}/og.jpg`}/>
				<meta property="og:description" content="Honey Computing — Cloud"/>
				<meta property="og:image:width" content="1200"/>
				<meta property="og:image:height" content="630"/>
				<meta name="msapplication-TileColor" content="#FFFFFF"/>
				<meta name="msapplication-TileImage" content={`${data.site.siteMetadata.siteUrl}/mstile-144x144.png`}/>
				<title>{data.site.siteMetadata.title}</title>
				<script type="text/javascript" src="/scripts/widgets.js"/>
				{[57, 72, 114, 120, 144, 152].map((size) =>
					<link rel="apple-touch-icon-precomposed" sizes={`${size}x${size}`} key={`${size}`}
					href={`${data.site.siteMetadata.siteUrl}/apple-touch-icon-${size}x${size}.png`}/>
				)}
				{[32, 16].map((size) =>
					<link rel="icon" type="image/png" sizes={`${size}x${size}`} key={`${size}`}
					href={`${data.site.siteMetadata.siteUrl}/favicon-${size}x${size}.png`}/>
				)}
			</Helmet>
			<main className={styles.cinema}>
				<section>
					<video controls autoPlay><source src={`/video/${source}.mp4`} type="video/mp4"/></video>
				</section>
				<section>
					<button className="initiate" onClick={skipClicked}><h4>Skip</h4></button>
				</section>
				<section>
					<a className="twitter-share-button" data-size="large" href="https://twitter.com/intent/tweet?text=Just%20bred%20my%20%40TwoBitBears%20on%20the%20Honey%20Cubs.Cloud%20%E2%80%94%20Check%20out%20my%20Hyper-Realistic%20mating%20process..."></a>
				</section>
			</main>
		</>
	)
}
